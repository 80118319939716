import React, { useEffect, useState } from "react";
import "./services.css";
import dataService from '../../data/services.json';

const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  // Data Service
  const [data, setData] = useState(null);
  useEffect(() => {
    setData(dataService);
  }, []);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="services section" id="services">
      <h2 className="section__title">Kinh nghiệp làm việc</h2>
      <span className="section__subtitle">Những gì tôi đã làm</span>

      <div className="services__container container grid">
        {
          data?.map((item, index) => (
            <div className="services__content" key={index}>
              <div>
                <i className="uil uil-arrow services__icon"></i>
                <h3 className="services__title">
                  {item.nameService}
                </h3>
              </div>

              <span
                className="services__button"
                onClick={() => {
                  toggleTab(item.toggleState);
                }}
              >
                Xem thêm
                <i className="uil uil-arrow-right services__button-icon"></i>
              </span>

              <div
                className={
                  toggleState === item.toggleState
                    ? "services__modal active-modal"
                    : "services__modal"
                }
              >
                <div className="services__modal-content">
                  <i
                    className="uil uil-times services__modal-close"
                    onClick={() => {
                      toggleTab(0);
                    }}
                  ></i>
                  <h3 className="services__modal-title">{item.nameService}</h3>
                  <p className="services__modal-description">
                    {item.descriptionService}
                  </p>
                  <p className="services__modal-description">
                    {item.timeService}
                  </p>
                  <ul className="service__modal-services grid">
                    {
                      item.detailServices?.map((detail, index) => (
                        <li className="services__modal-service" key={index}>
                          <i className="uil uil-check-circle services__modal-icon"></i>
                          <p className="services__modal-info">
                            {detail.detailName}
                          </p>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  );
};

export default Services;
