import React, { useEffect, useState } from 'react'
import "./header.css"
import dataHeader from '../../data/header.json';

const Header = () => {
  // Background Header
  window.addEventListener('scroll', function () {
    const header = this.document.querySelector('.header')
    if (this.scrollY >= 80) header.classList.add('scroll-header')
    else header.classList.remove('scroll-header')

  })
  // Toggle Menu
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState("#home")
  // Data header
  const [data, setData] = useState(null);
  useEffect(() => {
    setData(dataHeader);
  }, []);

  return (
    <header className="header">
      <nav className="nav container">
        <a href="index.html" className="nav__logo">Eren</a>

        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            {
              data?.map((item, index) => (
                <li className="nav__item" key={index}>
                  <a href={item.headerRef} onClick={() => setActiveNav(item.headerRef)} className={activeNav === item.headerRef ? "nav__link active-link" : "nav__link"}>
                    <i className={item.headerIcon}></i>{item.headerName}
                  </a>
                </li>
              ))
            }
          </ul>

          <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
        </div>

        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  )
}

export default Header