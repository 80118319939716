import React, { useEffect, useState } from "react";
import dataInfo from '../../data/infor.json';

const Info = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    setData(dataInfo);
  }, []);
  return (
    <div className="about__info grid">
      {
        data?.map((item, index) => (
          <div className="about__box" key={index}>
            <i className={item.inforIcon}></i>
            <h3 className="about__title">{item.inforName}</h3>
            <span className="about__subtitle">{item.inforSubtitle}</span>
          </div>
        ))
      }
    </div>
  );
};

export default Info;
