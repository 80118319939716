import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <footer className="footer">
      <div className='footer__container container'>
        <h1 className="footer__title">Đức Quyết</h1>

        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">Về tôi</a>
          </li>
          <li>
            <a href="#services" className="footer__link">Kinh nghiệm</a>
          </li>
          <li>
            <a href="#qualification" className="footer__link">Trình độ</a>
          </li>
        </ul>

        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/quyet17/"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="uil uil-linkedin-alt"></i>
          </a>
          <a
            href="https://github.com/quyetnd1711"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="uil uil-github-alt"></i>
          </a>
          <a
            href="https://www.facebook.com/ndquyet.17"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="uil uil-facebook-f"></i>
          </a>
        </div>

        <span className='footer__copy'>© Copyright {new Date().getFullYear()} - Đức Quyết</span>
      </div>
    </footer>
  )
}

export default Footer