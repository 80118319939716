import './App.css';
import About from './components/about/About';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Skills from './components/skills/Skills';
import Services from './components/services/Services'
import Qualification from './components/qualification/Qualification';
// import Testimobials from './components/testimobials/Testimobials';
import Contact from './components/contact/Contact';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';

function App() {
  return (
    <div>
      <Header/>
      
      <main className='main'>
        <Home/>
        <About/>
        <Skills/>
        <Services/>
        <Qualification/>
        {/* <Testimobials/> */}
        <Contact/>
        <ToastContainer />
      </main>

      <Footer/>
      <ScrollUp/>
    </div>
  );
}

export default App;
