import React, { useEffect, useState } from "react";
import "./qualification.css";
import dataStudies from '../../data/qualification1.json';
import dataWorks from '../../data/qualification2.json';

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  // Data study
  const [dataStudy, setDataStudy] = useState(null);
  useEffect(() => {
    setDataStudy(dataStudies);
  }, []);

  // Data study
  const [dataWork, setDataWork] = useState(null);
  useEffect(() => {
    setDataWork(dataWorks);
  }, []);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <section className="qualification section" id="qualification">
      <h2 className="section__title">Trình độ chuyên môn</h2>
      <span className="section__subtitle">Hành trình của tôi</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Học vấn
          </div>

          <div
            className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"}
            onClick={() => toggleTab(2)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Kinh Nghiệm
          </div>
        </div>

        <div className="qualification__sections">
          <div className={toggleState === 1 ?
            "qualification__content qualification__content-active" : "qualification__content"
          }>
            {
              dataStudy?.map((item, index) => (
                <div className="qualification__data" key={index}>
                  <div>
                    <h3 className="qualification__title">
                      {item.name}
                    </h3>
                    <span className="qualification__subtitle">
                      {item.detail}
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>{item.time}
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>
              ))
            }
          </div>
          <div className={toggleState === 2 ?
            "qualification__content qualification__content-active" : "qualification__content"
          }>
            {
              dataWork?.map((item, index) => (
                <div className="qualification__data" key={index}>
                  <div>
                    <h3 className="qualification__title">
                      {item.name}
                    </h3>
                    <span className="qualification__subtitle">
                      {item.detail}
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt"></i>{item.time}
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
