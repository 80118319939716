import React from 'react'
import "./skills.css"
import Fontend from './Fontend'
import Backend from './Backend'

const Skills = () => {
  return (
    <section className="skills section" id='skills'>
      <h2 className="section__title">Kỹ năng</h2>
      <span className="section__subtitle">Trình độ kỹ thuật của tôi</span>

      <div className="skills__container container grid">
        <Fontend/>
        <Backend/>
      </div>
    </section>
  )
}

export default Skills